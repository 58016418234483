export default {
  "cockpit-list": "TL",
  "cockpit-item": "TT",
  "cockpit-item-tile": "Tz",
  "guarding-title": "Ta",
  "guarding-title-icon": "TH",
  "subtitle-link": "TO",
  "subtitle-wrapper": "Tm",
  "subtitle-tooltip": "Tp"
};
