export default {
  "header-cell": "HL",
  "empty": "HT",
  "right": "Hz",
  "debitor": "Ha",
  "date": "HH",
  "status": "HO",
  "amount": "Hm",
  "header-amount": "Hp",
  "header-content": "Hx caption-bold",
  "active": "Hw"
};
