export default {
  "panel-container": "dm",
  "selector-container": "dp",
  "container-title": "dx",
  "panel-title": "dw",
  "panel-title-text": "dN",
  "panel-icon": "dv",
  "icon": "do",
  "animate-in": "dn",
  "panel-content": "di",
  "error-message": "dA"
};
