export default {
  "wrapper": "ln",
  "content-wrapper": "li",
  "no-fee": "lA",
  "concierge": "lY",
  "no-fee-item": "lh",
  "concierge-item": "lf",
  "no-fee-icon": "lK",
  "lounge-icon": "lG",
  "insurance-conditions": "lr",
  "description": "lb",
  "footer-detail": "ly",
  "title": "lU",
  "footer-price": "lj",
  "footer-content": "lW",
  "lounge-text": "lu",
  "lounge": "XS",
  "lounge-images": "Xc",
  "img": "Xq",
  "footer-sticky-bar": "XZ",
  "visible": "XR",
  "content": "XQ",
  "content-card": "Xe"
};
