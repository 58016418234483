export default {
  "row": "LN",
  "active": "Lv",
  "cell": "Lo",
  "empty-cell": "Ln",
  "counterparty": "Li",
  "counterparty-info": "LA",
  "name": "LY body-1",
  "qonto": "Lh",
  "currency": "Lf",
  "account-number": "LK caption",
  "custom-labels": "LG",
  "label": "Lr"
};
