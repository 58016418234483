export default {
  "dual-nav": "mQ",
  "header": "me",
  "expanded": "mB",
  "collapsed": "mE",
  "responsive": "md",
  "overlay": "mI",
  "visible": "mt",
  "footer": "mM",
  "scrolled-top": "mP",
  "scrollable-content": "ml",
  "safari": "mX"
};
