/* import __COLOCATED_TEMPLATE__ from './initial-trial.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeHighlight } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface DiscoverUpsellInitialTrialCtaSignature {
  // The arguments accepted by the component
  Args: {
    isRunning?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DiscoverUpsellInitialTrialCtaComponent extends Component<DiscoverUpsellInitialTrialCtaSignature> {
  badgeHighlight = BadgeHighlight;

  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sentry: Services['sentry'];

  constructor(owner: unknown, args: DiscoverUpsellInitialTrialCtaSignature['Args']) {
    super(owner, args);
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.initTask.perform();
  }

  initTask = dropTask(async () => {
    // @ts-expect-error
    if (!this.args.upsellTrigger) {
      return;
    }
    try {
      let {
        recommended_recurrence: recurrence,
        recommended_product: { code },
        // @ts-expect-error
      } = await this.subscriptionManager.upgradeRecommendation(this.args.upsellTrigger);

      return { code, recurrence };
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get activeTrialRemainingDays() {
    return this.subscriptionManager.currentSubscription?.activeTrialRemainingDays;
  }

  get hasInitialTrialUpsell() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrialUpsell;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::Upsell::InitialTrial': typeof DiscoverUpsellInitialTrialCtaComponent;
  }
}
