export default {
  "rule-card": "DS",
  "rule-content": "Dc",
  "rule-options": "Dq",
  "visible": "DZ",
  "dropdown-btn": "DR",
  "delete-btn": "DQ",
  "btn--tertiary": "De",
  "delete-rule-btn": "DB",
  "transaction-estimate": "DE"
};
