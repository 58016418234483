export default {
  "container": "nQ",
  "header-wrapper": "ne mb-16",
  "header": "nB",
  "logo": "nE mr-16",
  "title": "nd title-4",
  "description": "nI body-2",
  "conditions": "nt",
  "label": "nM caption mb-4",
  "tags": "nP"
};
