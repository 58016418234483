export default {
  "header": "PA",
  "hidden": "PY",
  "header-inline": "Ph",
  "header-inline__search-bar": "Pf",
  "header-bottom": "PK",
  "margin-top": "PG",
  "header-bottom-loading": "Pr",
  "header-bottom-loading-state": "Pb",
  "actions__filters": "Py",
  "actions__filters__toggle-btn": "PU",
  "tabs": "Pj",
  "header-inline__filters": "PW",
  "filter-ml": "Pu",
  "filter-clear": "lS"
};
