export default {
  "wrapper": "pV",
  "active": "pL",
  "expanded": "pT",
  "fade-in": "pz",
  "hidden": "pa",
  "visible": "pH",
  "item-list": "pO",
  "item-list-slide-down": "pm",
  "dropdown-trigger": "pp overlay",
  "settings-dropdown-slide-down": "px",
  "responsive": "pw",
  "is-selected": "pN",
  "is-active": "pv",
  "dropdown": "po"
};
