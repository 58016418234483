export default {
  "wrapper": "Tc",
  "label": "Tq",
  "input": "TZ",
  "icon-trash": "TR",
  "hidden": "TQ",
  "icon-color": "Te",
  "icon-edit": "TB",
  "icon-edit-save": "TE",
  "button-container": "Td"
};
