export default {
  "form": "om",
  "fieldset": "op",
  "form-body": "ox",
  "conditional-select": "ow",
  "conditional-text": "oN",
  "group": "ov",
  "add-filter-button": "oo btn btn--tertiary",
  "footer": "on",
  "btn-icon": "oi",
  "add-icon": "oA",
  "cancel-button": "oY btn",
  "form-header": "oh",
  "align-start": "of",
  "align-right": "oK",
  "button-wrapper": "oG",
  "wrapper": "or",
  "label": "ob body-2",
  "input-wrapper": "oy",
  "input": "oU input-field body-2",
  "error": "oj",
  "error-message": "oW"
};
