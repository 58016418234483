/* import __COLOCATED_TEMPLATE__ from './checkbox-field.hbs'; */
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';

interface F24FormFieldsCheckboxFieldSignature {
  // The arguments accepted by the component
  Args: {
    isChecked?: boolean;
    isDisabled?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class F24FormFieldsCheckboxFieldComponent extends Component<F24FormFieldsCheckboxFieldSignature> {
  checkbox = Checkbox;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::FormFields::CheckboxField': typeof F24FormFieldsCheckboxFieldComponent;
  }
}
