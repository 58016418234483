export default {
  "cell": "Ez caption-bold",
  "empty": "Ea",
  "transaction": "EH",
  "member": "EO",
  "method": "Em",
  "operation-date": "Ep",
  "amount": "Ex",
  "label": "Ew caption-bold",
  "active": "EN"
};
