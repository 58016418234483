export default {
  "main": "Rt",
  "spinner": "RM",
  "error": "RP",
  "error-illustration": "Rl",
  "wrapper": "RX",
  "content": "RC",
  "illustration": "Rk mb-32",
  "title": "RJ mb-16 title-2",
  "subtitle": "Rs body-2"
};
