export default {
  "tooltip": "mU",
  "wrapper": "mj",
  "collapsed": "mW",
  "label": "mu body-1",
  "selected": "pS",
  "icon-wrapper": "pc",
  "icon": "pq",
  "locked-icon": "pZ",
  "notification-count": "pR",
  "fade-in": "pQ",
  "responsive": "pe"
};
