export default {
  "row": "zk body-1",
  "cell": "zJ",
  "empty": "zs",
  "amount": "zF",
  "review": "zg",
  "cell-content": "zD",
  "icon": "zV",
  "name": "zL",
  "name-text": "zT",
  "quick-actions": "zz",
  "account-select": "za"
};
