export default {
  "modal": "VT",
  "features": "Vz",
  "close-button": "Va",
  "header": "VH title-2",
  "header-text": "VO body-2",
  "feature": "Vm",
  "subheader": "Vp title-4",
  "text": "Vx body-2",
  "cta": "Vw",
  "container-img": "VN",
  "img": "Vv",
  "link": "Vo"
};
