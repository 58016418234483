export default {
  "row": "tp",
  "active": "tx",
  "cell": "tw body-2",
  "empty": "tN",
  "no-padding": "tv",
  "declined": "to",
  "not-declined": "tn",
  "item-amount-declined": "ti",
  "cell-content": "tA",
  "item-avatar": "tY mr-16",
  "avatar": "th",
  "item-card-name": "tf",
  "item-card-name-text": "tK",
  "item-amount": "tG body-1"
};
