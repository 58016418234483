/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

import { Header } from 'qonto/react/components/transfers/table/header';
import { TableItem } from 'qonto/react/components/transfers/table/item';

interface EmptyStatesTransfersDummyDataSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EmptyStatesTransfersDummyDataComponent extends Component<EmptyStatesTransfersDummyDataSignature> {
  header = Header;
  item = TableItem;
  sort = 'next_recursion_date:desc';
  query = {};
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EmptyStates::Transfers::DummyData': typeof EmptyStatesTransfersDummyDataComponent;
  }
}
