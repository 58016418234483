export default {
  "wrapper": "aS",
  "header": "ac",
  "status": "aq",
  "close-button": "aZ",
  "slug": "aR",
  "category": "aQ",
  "category-title": "ae",
  "activity-tag-select": "aB",
  "footer": "aE"
};
