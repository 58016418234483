/* import __COLOCATED_TEMPLATE__ from './share.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DEFAULT_LOCALE } from '@repo/shared-config/constants/locales';
import { reads } from 'macro-decorators';

import { CopyButton } from 'qonto/react/shared/components/copy-button';
// @ts-expect-error
import computedDomiciliation from 'qonto/utils/computed-domiciliation';
import { formatIban } from 'qonto/utils/format-input';

interface BankAccountShareSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class BankAccountShareComponent extends Component<BankAccountShareSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare featuresManager: Services['featuresManager'];
  @service declare userManager: Services['userManager'];

  // @ts-expect-error
  @reads('featuresManager.flags.accountDetailsIntermediaryBic') isIntermediaryBicEnabled;
  // @ts-expect-error
  @reads('userManager.currentUser') user;

  CopyButton = CopyButton;

  @action
  displayCopyToast() {
    this.toastFlashMessages.toastInfo(this.intl.t('bank_accounts.notifications.account_details'));
    this.segment.track('account_details_copied');
  }

  get currentUserLocal() {
    return (
      // @ts-expect-error
      this.intl.getLocaleCodes.find(code => code === this.user.localeObject.code) || DEFAULT_LOCALE
    );
  }

  get accountToClipboard() {
    // @ts-expect-error
    let { bankAccount, organization } = this.args;

    return [
      `${this.intl.t('bank_accounts.clipboard_labels.beneficiary')} ${organization.name}`,
      // @ts-expect-error
      `${this.intl.t('bank_accounts.clipboard_labels.iban')} ${formatIban(bankAccount.iban)}`,
      `${this.bicLabel} ${bankAccount.bic}`,
      this.correspondentBankLabel,
      `${this.intl.t('bank_accounts.clipboard_labels.owner_address')} ${
        organization.address.fullAddress
      }`,
      ``,
      this.intl.t('bank_accounts.clipboard_labels.additional_details'),
      `${this.intl.t('bank_accounts.clipboard_labels.domiciliation')} ${this.bankDomiciliation}`,
      `${this.intl.t('bank_accounts.clipboard_labels.bank')} ${bankAccount.bankCode}`,
      bankAccount.branchCode &&
        `${this.intl.t('bank_accounts.clipboard_labels.branch')} ${bankAccount.branchCode}`,
      `${this.intl.t('bank_accounts.clipboard_labels.account')} ${bankAccount.accountNumber}`,
      bankAccount.controlDigits &&
        `${this.intl.t('bank_accounts.clipboard_labels.key')} ${bankAccount.controlDigits}`,
    ]
      .filter(v => v !== null)
      .join(`\n`);
  }

  get bicLabel() {
    // @ts-expect-error
    return this.args.bankAccount.isSwiftEnabled
      ? this.intl.t('bank_accounts.clipboard_labels.bic_swift')
      : this.intl.t('bank_accounts.clipboard_labels.bic');
  }

  get correspondentBankLabel() {
    // @ts-expect-error
    return this.args.bankAccount.isSwiftEnabled
      ? this.intl.t('bank_accounts.clipboard_labels.correspondent_bank')
      : null;
  }

  // @ts-expect-error
  @computedDomiciliation() bankDomiciliation;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccount::Share': typeof BankAccountShareComponent;
  }
}
