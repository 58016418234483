export default {
  "header": "TG",
  "header__main": "Tr",
  "note": "Tb",
  "review-status": "Ty",
  "reviewer-wrapper": "TU",
  "reviewer": "Tj",
  "decline-note": "TW",
  "decline-note-title": "Tu",
  "status-dot": "zS",
  "approved": "zc",
  "rejected": "zq"
};
