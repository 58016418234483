/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import {
  LottiePlayer,
  SearchFieldWithDebounce,
  SkeletonLoader,
  Spinner,
} from '@repo/design-system-kit';
import dayjs from 'dayjs';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { STATUS } from 'qonto/constants/receivable-invoice';
import { InvoiceDetailsCard } from 'qonto/react/components/attachments/card/invoice-details-card';
import { InvoiceDetailsCardLoading } from 'qonto/react/components/attachments/loading/invoice-details-card-loading';

interface AttachmentsAttachmentsSuggestedSidebarSignature {
  // The arguments accepted by the component
  Args: {
    isSearchError?: boolean;
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class AttachmentsAttachmentsSuggestedSidebarComponent extends Component<AttachmentsAttachmentsSuggestedSidebarSignature> {
  placeholderLine = SkeletonLoader.Line;
  placeholderBlock = SkeletonLoader.Block;
  spinner = Spinner;
  invoiceDetailsCard = InvoiceDetailsCard;
  invoiceDetailsCardLoading = InvoiceDetailsCardLoading;
  lottiePlayer = LottiePlayer;
  searchField = SearchFieldWithDebounce;

  @service declare intl: Services['intl'];

  @tracked scrolledTop = true;

  get displayPagination() {
    // @ts-expect-error
    return this.args.totalCount && this.args.totalCount > 25 && !this.args.isSearchError;
  }

  get showEmptyResultsState() {
    let hasInvoices =
      // @ts-expect-error
      Boolean(this.args.supplierInvoices?.length) || Boolean(this.args.receivableInvoices?.length);
    // @ts-expect-error
    let { invoiceStatus, issueDate, searchQuery, selectedPeriod } = this.args;
    let hasFilters =
      Boolean(invoiceStatus) ||
      Boolean(issueDate) ||
      Boolean(searchQuery) ||
      Boolean(selectedPeriod);

    return !hasInvoices && hasFilters;
  }

  get maxDate() {
    return dayjs().toDate();
  }

  get filteredReceivableInvoices() {
    // @ts-expect-error
    return this.args.receivableInvoices?.filter(invoice => invoice.status !== STATUS.DRAFT);
  }

  get shouldShowSuggestionsTitle() {
    // @ts-expect-error
    let { transaction, searchQuery, selectedPeriod } = this.args || {};
    let { attachmentSuggestionIds } = transaction || {};
    return attachmentSuggestionIds?.length && !searchQuery && !selectedPeriod;
  }

  get placeholderText() {
    // @ts-expect-error
    let { creditNote } = this.args || {};
    return creditNote?.isCreditNote
      ? this.intl.t('find-invoice-modal.credit-notes.search.placeholder')
      : this.intl.t('find-invoice-modal.search.placeholder');
  }

  // @ts-expect-error
  shouldShowSignus(invoice) {
    return !(variation('feature--boolean-ap-credit-notes') && !invoice?.isCreditNote);
  }

  @action
  // @ts-expect-error
  updateScrolledTop(value) {
    this.scrolledTop = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::InvoiceAttachmentsSuggested::Sidebar': typeof AttachmentsAttachmentsSuggestedSidebarComponent;
  }
}
