export default {
  "wrapper": "xO",
  "content": "xm",
  "compact": "xp",
  "full-width": "xx",
  "left": "xw",
  "right-image": "xN",
  "right-lottie": "xv",
  "badge": "xo",
  "badge-icon": "xn",
  "animation": "xi",
  "hidden": "xA"
};
