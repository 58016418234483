/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import {
  USER_ACTIONS_CTA_BUTTON_TYPE,
  USER_ACTIONS_CTA_TYPE,
  USER_ACTIONS_ILLUSTRATION_TYPE,
  USER_ACTIONS_STATUS,
  USER_ACTIONS_TYPE,
  // @ts-expect-error
} from 'qonto/constants/user-actions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

interface ApprovalWorkflowsTableSignature {
  // The arguments accepted by the component
  Args: {
    origin: string;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export const LOCAL_STORAGE_AW_PRODUCT_DISCOVERY = 'APPROVAL_WORKFLOWS_PRODUCT_DISCOVERY';
export const APPROVAL_WORKFLOWS_USER_ACTION = 'approval-workflows-upsell-action';

export default class ApprovalWorkflowsTable extends Component<ApprovalWorkflowsTableSignature> {
  @service declare intl: Services['intl'];
  @service declare abilities: Services['abilities'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare upsellManager: Services['upsellManager'];

  @tracked cachedProductDiscoveryAction = JSON.parse(
    safeLocalStorage.getItem(LOCAL_STORAGE_AW_PRODUCT_DISCOVERY) || '{}'
  );

  get shouldDisplay() {
    let hasFeatureFlag = variation('feature--boolean-approval-workflows-discovery');

    let canUpdate = this.abilities.can('update subscription');

    let isBlockedForItaly =
      variation('feature--boolean-italy-fee-upsell') &&
      this.organizationManager.organization.legalCountry === 'IT';
    let isDeactivated = this.organizationManager.organization.isDeactivated;

    let isTeamPlan = this.subscriptionManager.hasFeature('team_management');
    let hasApprovalWorkflows = this.subscriptionManager.hasFeature('approval_workflows');
    let shouldHideUpsell = this.subscriptionManager.shouldHideUpsell;

    let isDismissed =
      this.cachedProductDiscoveryAction[APPROVAL_WORKFLOWS_USER_ACTION] ===
      USER_ACTIONS_STATUS.DISMISSED;

    return (
      hasFeatureFlag &&
      !hasApprovalWorkflows &&
      !isDismissed &&
      isTeamPlan &&
      canUpdate &&
      !isBlockedForItaly &&
      !shouldHideUpsell &&
      !isDeactivated
    );
  }

  get pricePlanCode() {
    return this.subscriptionManager.currentPricePlan?.code;
  }

  get userActions() {
    return [
      {
        name: APPROVAL_WORKFLOWS_USER_ACTION,
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.DISCOVERY,
        descriptionText: this.intl.t('user-actions.discovery-cards.approval-workflows.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.BUTTON,
          buttonType: USER_ACTIONS_CTA_BUTTON_TYPE.PRIMARY,
          onClick: this.goToApprovalWorkflows,
          text: this.hasAvailableFreeTrial
            ? this.intl.t('user-actions.discovery-cards.approval-workflows.try-for-free')
            : this.intl.t('user-actions.discovery-cards.approval-workflows.get-started'),
        },
        illustration: {
          // @TODO update this illustration
          name: 'files-import-invoice',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      },
    ];
  }

  get hasAvailableFreeTrial() {
    return this.upsellManager.hasAvailableFreeTrial('approval_workflows');
  }

  onDismissCardTask = dropTask(async () => {
    this.cachedProductDiscoveryAction = {
      [APPROVAL_WORKFLOWS_USER_ACTION]: USER_ACTIONS_STATUS.DISMISSED,
    };
    await safeLocalStorage.setItem(
      LOCAL_STORAGE_AW_PRODUCT_DISCOVERY,
      JSON.stringify(this.cachedProductDiscoveryAction)
    );
  });

  @action
  goToApprovalWorkflows() {
    this.router.transitionTo('settings.approval-workflows');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApprovalWorkflow::ProductDiscovery::Card': typeof ApprovalWorkflowsTable;
  }
}
