export default {
  "wrapper": "OJ",
  "badge-trial": "Os",
  "header": "OF",
  "list": "Og body-2",
  "main": "OD",
  "card": "OV",
  "container": "OL",
  "header-button": "OT",
  "error-text": "Oz",
  "mt-16": "Oa"
};
