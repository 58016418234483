export default {
  "header": "qI",
  "header-type": "qt",
  "header-infos": "qM",
  "title": "qP",
  "close": "ql",
  "close-icon": "qX",
  "subtitle": "qC",
  "date": "qk",
  "container": "qJ",
  "picto": "qs",
  "icon": "qF",
  "general": "qg",
  "amount": "qD",
  "infobox": "qV"
};
