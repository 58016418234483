export default {
  "mandates": "xU",
  "mandates-list": "xj",
  "left-section": "xW",
  "header-search": "xu",
  "header": "wS",
  "header-content": "wc",
  "search": "wq",
  "search-bar": "wZ",
  "body": "wR",
  "list-title": "wQ caption-bold",
  "details": "we",
  "avatar": "wB mr-16",
  "header-beneficiary": "wE",
  "header-beneficiary-activity": "wd",
  "header-beneficiary-details": "wI",
  "header-beneficiary-details-recurring": "wt",
  "infos": "wM",
  "infos-title": "wP",
  "infos-empty": "wl",
  "infos-list": "wX",
  "infos-list-item": "wC",
  "infos-list-item-left": "wk",
  "infos-list-item-right": "wJ",
  "infos-list-item-attachment": "ws"
};
