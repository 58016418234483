export default {
  "hero-gradient": "XB",
  "hero-wrapper": "XE",
  "container-text": "Xd",
  "title-with-badge": "XI",
  "recycled-badge": "Xt",
  "hero-label": "XM",
  "hero-card": "XP",
  "svg-gradient": "Xl",
  "design": "XX",
  "design-image-container": "XC",
  "design-image-overlay": "Xk",
  "design-item": "XJ",
  "hero-title": "Xs",
  "hero-subtitle": "XF",
  "metal-illustration": "Xg",
  "cards": "XD",
  "metal-illustration-old": "XV",
  "rotating": "XL",
  "verticaling": "XT",
  "card": "Xz",
  "circle": "Xa"
};
