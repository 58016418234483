export default {
  "not-active": "BN",
  "title": "Bv",
  "details": "Bo",
  "detail": "Bn",
  "text-secondary": "Bi",
  "text-primary-a": "BA",
  "amounts": "BY",
  "contents-container": "Bh",
  "contents": "Bf",
  "menu-item": "BK",
  "archive": "BG",
  "amount-settled": "Br",
  "shadow": "Bb",
  "name": "By title-4",
  "tooltip": "BU"
};
