export default {
  "header-cell": "kp",
  "border-top": "kx",
  "header-content": "kw caption-bold",
  "active": "kN",
  "empty": "kv",
  "card": "ko",
  "card-holder": "kn",
  "limit": "ki",
  "align-right": "kA",
  "status": "kY",
  "hidden": "kh",
  "no-padding": "kf"
};
