export default {
  "details": "Lq",
  "top-details": "LZ",
  "name": "LR",
  "account-infobox": "LQ",
  "row": "Le",
  "icon": "LB",
  "account-number": "LE",
  "currency": "Ld",
  "activity-tag": "LI",
  "activity-icon": "Lt",
  "edit-btn": "LM",
  "action-buttons-container": "LP"
};
