export default {
  "budget-edit-form": "dq",
  "period-label": "dZ",
  "periods": "dR",
  "yearly": "dQ",
  "tooltips": "de",
  "year": "dB",
  "total-budget": "dE",
  "total-budget-value": "dd",
  "divider": "dI",
  "buttons": "dt"
};
