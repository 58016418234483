export default {
  "row": "HS",
  "icon": "Hc",
  "cell": "Hq",
  "empty": "HZ",
  "no-padding": "HR",
  "cell-content": "HQ",
  "right": "He",
  "cube": "HB",
  "row--sidebar-open": "HE",
  "status": "Hd"
};
