export default {
  "card-sidebar-header": "MS",
  "content": "Mc",
  "card-image-container": "Mq",
  "card-image": "MZ",
  "legacy": "MR",
  "type": "MQ",
  "type-container": "Me",
  "type-close": "MB",
  "type-status-awaiting": "ME",
  "type-status-live": "Md",
  "type-status-paused": "MI",
  "type-status-ghost": "Mt",
  "actions-panel": "MM",
  "actions-panel-header": "MP",
  "action-btn": "Ml",
  "disclaimer": "MX",
  "infobox": "MC",
  "btn-panel": "Mk",
  "required-action-btn": "MJ",
  "kyc-disclaimer": "Ms"
};
