export default {
  "item": "pl",
  "qonto-pilot-nav-item": "pX",
  "wrapper": "pC",
  "collapsed": "pk",
  "label": "pJ body-1",
  "icon-wrapper": "ps",
  "icon": "pF",
  "key": "pg",
  "keys": "pD"
};
