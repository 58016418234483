export default {
  "card": "IX",
  "avatar": "IC",
  "content": "Ik",
  "content-top": "IJ",
  "fullname": "Is",
  "representative": "IF",
  "icon": "Ig",
  "type": "ID",
  "menu-container": "IV",
  "dropdown-menu": "IL",
  "menu-item": "IT body-2",
  "delete": "Iz"
};
