export default {
  "wrapper": "QG",
  "title": "Qr",
  "button-icon": "Qb",
  "download-button": "Qy",
  "specification-list": "QU",
  "certified-details-wrapper": "Qj",
  "details-wrapper": "QW",
  "certified-specification-list": "Qu",
  "specification": "eS",
  "icon": "ec",
  "cross": "eq",
  "checkmark": "eZ",
  "pit-link": "eR"
};
