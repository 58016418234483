/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';

interface DirectDebitCollectionsTableRowSignature {
  // The arguments accepted by the component
  Args: {
    isHighlighted?: boolean;
    isSidebarOpen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class DirectDebitCollectionsTableRow extends Component<DirectDebitCollectionsTableRowSignature> {
  badgeStatus = BadgeStatus;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::Table::Row': typeof DirectDebitCollectionsTableRow;
  }
}
