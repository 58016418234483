export default {
  "card": "Ib",
  "avatar": "Iy",
  "owner": "IU",
  "content": "Ij",
  "identity": "IW",
  "fullname": "Iu",
  "type": "tS",
  "menu-container": "tc",
  "dropdown-menu": "tq",
  "menu-item": "tZ body-2",
  "delete": "tR"
};
