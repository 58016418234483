export default {
  "container": "MF",
  "is-sidebar-content": "Mg",
  "no-sep": "MD",
  "details": "MV",
  "detail": "ML",
  "name": "MT",
  "content": "Mz",
  "content-text": "Ma",
  "break-word": "MH",
  "primary": "MO",
  "links-container": "Mm",
  "infos-container": "Mp",
  "infos-disclaimer": "Mx",
  "upsell-container": "Mw"
};
