export default {
  "sidebar": "sf",
  "sidebar-header": "sK",
  "close-button": "sG",
  "setup-mode-content": "sr",
  "edit-mode-content": "sb",
  "reminders-header": "sy",
  "reminders-title-container": "sU",
  "reminders-title": "sj",
  "reminders-subtitle": "sW",
  "reminders-list": "su",
  "reminder": "FS",
  "reminder-due-date": "Fc",
  "upsell-button-container": "Fq"
};
