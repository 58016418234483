export default {
  "row": "kK",
  "active": "kG",
  "animated": "kr",
  "fadein-item": "kb",
  "cell": "ky body-2",
  "cell-content": "kU",
  "no-padding": "kj",
  "hidden": "kW",
  "disabled": "ku",
  "not-disabled": "JS",
  "card-icon": "Jc",
  "card-infos": "Jq",
  "card-digits": "JZ",
  "card-digits-warning": "JR",
  "status-puce": "JQ",
  "live": "Je",
  "waiting-funds": "JB",
  "ghost": "JE",
  "payment-limit-bar": "Jd",
  "align-right": "JI"
};
