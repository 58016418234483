/* import __COLOCATED_TEMPLATE__ from './upsell.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Badge, BadgeHighlight, LottiePlayer } from '@repo/design-system-kit';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';
import { importAsset } from 'qonto/helpers/import-asset';

const UPSELL_IMAGES = {
  bookkeeping: {
    de: '/illustrations/upsell/de/bookeeping-empty-state-upsell.png',
    en: '/illustrations/upsell/en/bookeeping-empty-state-upsell.png',
    es: '/illustrations/upsell/es/bookeeping-empty-state-upsell.png',
    fr: '/illustrations/upsell/fr/bookeeping-empty-state-upsell.png',
    it: '/illustrations/upsell/it/bookeeping-empty-state-upsell.png',
  },
  supplierInvoices: {
    de: '/illustrations/upsell/de/supplier-invoices-empty-state-upsell.png',
    en: '/illustrations/upsell/en/supplier-invoices-empty-state-upsell.png',
    es: '/illustrations/upsell/es/supplier-invoices-empty-state-upsell.png',
    fr: '/illustrations/upsell/fr/supplier-invoices-empty-state-upsell.png',
    it: '/illustrations/upsell/it/supplier-invoices-empty-state-upsell.png',
  },
  requestsOwnerAdmin: {
    de: '/illustrations/upsell/de/card-requests-empty-state-upsell.png',
    en: '/illustrations/upsell/en/card-requests-empty-state-upsell.png',
    es: '/illustrations/upsell/es/card-requests-empty-state-upsell.png',
    fr: '/illustrations/upsell/fr/card-requests-empty-state-upsell.png',
    it: '/illustrations/upsell/it/card-requests-empty-state-upsell.png',
  },
  requestsAccountant: {
    de: '/illustrations/upsell/de/requests-empty-state-upsell.png',
    en: '/illustrations/upsell/en/requests-empty-state-upsell.png',
    es: '/illustrations/upsell/es/requests-empty-state-upsell.png',
    fr: '/illustrations/upsell/fr/requests-empty-state-upsell.png',
    it: '/illustrations/upsell/it/requests-empty-state-upsell.png',
  },
  requestsEmployee: {
    de: '/illustrations/upsell/de/card-requests-empty-state-upsell.png',
    en: '/illustrations/upsell/en/card-requests-empty-state-upsell.png',
    es: '/illustrations/upsell/es/card-requests-empty-state-upsell.png',
    fr: '/illustrations/upsell/fr/card-requests-empty-state-upsell.png',
    it: '/illustrations/upsell/it/card-requests-empty-state-upsell.png',
  },
  teamManagement: {
    de: '/illustrations/upsell/de/team-management-empty-state-upsell.png',
    en: '/illustrations/upsell/en/team-management-empty-state-upsell.png',
    es: '/illustrations/upsell/es/team-management-empty-state-upsell.png',
    fr: '/illustrations/upsell/fr/team-management-empty-state-upsell.png',
    it: '/illustrations/upsell/it/team-management-empty-state-upsell.png',
  },
  budget: {
    de: '/illustrations/upsell/de/budget-empty-state-upsell.png',
    en: '/illustrations/upsell/en/budget-empty-state-upsell.png',
    es: '/illustrations/upsell/es/budget-empty-state-upsell.png',
    fr: '/illustrations/upsell/fr/budget-empty-state-upsell.png',
    it: '/illustrations/upsell/it/budget-empty-state-upsell.png',
  },
  reimbursements: {
    de: '/illustrations/upsell/de/reimbursements-empty-state-upsell.svg',
    en: '/illustrations/upsell/en/reimbursements-empty-state-upsell.svg',
    es: '/illustrations/upsell/es/reimbursements-empty-state-upsell.svg',
    fr: '/illustrations/upsell/fr/reimbursements-empty-state-upsell.svg',
    it: '/illustrations/upsell/it/reimbursements-empty-state-upsell.svg',
  },
};

const UPSELL_LOTTIES = {
  tasks: '/lotties/tasks_empty-state.json',
};

interface DiscoverUpsellSignature {
  // The arguments accepted by the component
  Args: {
    isAddOnRedirectionTaskRunning?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class DiscoverUpsellComponent extends Component<DiscoverUpsellSignature> {
  lottiePlayer = LottiePlayer;
  badgeHighlight = BadgeHighlight;
  badge = Badge;

  @service declare localeManager: Services['localeManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare store: Services['store'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare modals: Services['modals'];

  TRACKING_TRIAL_STATE = TRACKING_TRIAL_STATE;

  constructor(owner: unknown, args: DiscoverUpsellSignature['Args']) {
    super(owner, args);
    let { code: price_plan } = this.subscriptionManager.currentPricePlan;
    this.segment.track('upsell_page_loads', {
      // @ts-expect-error
      empty_state_name: this.args.name,
      price_plan,
    });
  }

  get lottiePath() {
    // @ts-expect-error
    let { lottieType } = this.args;
    // @ts-expect-error
    return UPSELL_LOTTIES[lottieType];
  }

  get imagePath() {
    // @ts-expect-error
    let { imageType } = this.args;
    // @ts-expect-error
    return importAsset([UPSELL_IMAGES[imageType][this.localeManager.locale]]);
  }

  get trialInfo() {
    // @ts-expect-error
    return this.subscriptionManager.currentSubscription?.findTrial(this.args.upsellTrigger);
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasInitialTrialUpsell() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrialUpsell;
  }

  get initialTrialMonthDuration() {
    return this.subscriptionManager.currentSubscription?.activeTrial.month_duration;
  }

  get activeTrialRemainingDays() {
    return this.subscriptionManager.currentSubscription?.activeTrialRemainingDays;
  }

  @action onTrialClick() {
    let { code: price_plan } = this.subscriptionManager.currentPricePlan;
    let { role } = this.organizationManager.membership;

    this.segment.track('upsell_empty-state_clicked', {
      // @ts-expect-error
      empty_state_name: this.args.name,
      price_plan,
      role,
      trial_state: TRACKING_TRIAL_STATE.FREE_TRIAL,
    });

    this.modals.open('discover/trial/confirm', {
      isFullScreenModal: true,
      trialInfo: this.trialInfo,
    });
  }

  @action onClick() {
    let { code: price_plan } = this.subscriptionManager.currentPricePlan;
    let { role } = this.organizationManager.membership;
    // @ts-expect-error
    let { upsellTrigger, modal } = this.args;

    this.segment.track('upsell_empty-state_clicked', {
      // @ts-expect-error
      empty_state_name: this.args.name,
      price_plan,
      role,
      trial_state: TRACKING_TRIAL_STATE.NONE,
    });

    this.modals.open(modal, {
      isFullScreenModal: true,
      upsellTrigger,
      // @ts-expect-error
      name: this.args.name,
    });
  }

  @action onClickUpsell() {
    let { code: price_plan } = this.subscriptionManager.currentPricePlan;
    let { role } = this.organizationManager.membership;
    let { end_date } = this.subscriptionManager.currentSubscription?.activeTrial || {};

    this.segment.track('upsell_empty-state_clicked', {
      // @ts-expect-error
      empty_state_name: this.args.name,
      price_plan,
      role,
      trial_state: TRACKING_TRIAL_STATE.INITIAL_TRIAL,
      end_date,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::Upsell': typeof DiscoverUpsellComponent;
  }
}
