export default {
  "header-cell": "zd",
  "type": "zI",
  "request-date": "zt",
  "amount": "zM",
  "quick-actions": "zP",
  "empty": "zl",
  "header-content": "zX caption-bold",
  "active": "zC"
};
