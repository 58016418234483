export default {
  "wrapper": "NI",
  "subtitle": "Nt body-2",
  "legend": "NM",
  "table": "NP",
  "footer": "Nl",
  "footer-codice-1": "NX",
  "footer-codice-2": "NC",
  "footer-total-a": "Nk",
  "footer-total-b": "NJ",
  "footer-balance": "Ns",
  "errors": "NF"
};
