/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

// @ts-expect-error
import { STATUS } from 'qonto/constants/checks';

interface ChecksTableItemSignature {
  // The arguments accepted by the component
  Args: {
    isHighlighted?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class ChecksTableItem extends Component<ChecksTableItemSignature> {
  tag = Tag;

  @service declare intl: Services['intl'];

  get isAvatarDisabled() {
    // @ts-expect-error
    let { status } = this.args.item;

    return [STATUS.CANCELED, STATUS.DECLINED, STATUS.REFUNDED].includes(status);
  }

  get status() {
    // @ts-expect-error
    let { status, declinedReason } = this.args.item;

    let CHECKS_STATUSES = {
      canceled: {
        color: 'red',
        copy: this.intl.t('checks.status.canceled'),
      },
      declined: {
        color: 'red',
        copy:
          declinedReason === 'not_received'
            ? this.intl.t('checks.status.canceled')
            : this.intl.t('checks.status.declined'),
      },
      pending: {
        color: 'orange',
        copy: this.intl.t('checks.status.pending'),
      },
      received: {
        color: 'orange',
        copy: this.intl.t('checks.status.received'),
      },
      refunded: {
        color: 'red',
        copy: this.intl.t('checks.status.refunded'),
      },
      validated: {
        color: 'green',
        copy: this.intl.t('checks.status.validated'),
      },
    };

    // @ts-expect-error
    return CHECKS_STATUSES[status] ?? CHECKS_STATUSES['pending'];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checks::Table::Item': typeof ChecksTableItem;
  }
}
