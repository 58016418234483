export default {
  "envelope": "Tx",
  "email": "Tw",
  "logo": "TN",
  "subject": "Tv",
  "message": "To",
  "payment-details": "Tn",
  "contact": "Ti",
  "detail": "TA",
  "cta": "TY"
};
