export default {
  "header": "ns",
  "header-top": "nF",
  "header-inner": "ng",
  "disclaimer": "nD mb-16",
  "header-main-wrapper": "nV",
  "header-main": "nL",
  "logo": "nT mr-16",
  "header-right": "nz",
  "partner-description": "na body-2"
};
