export default {
  "card": "db",
  "row": "dy",
  "icon": "dU",
  "unrevokable": "dj",
  "active": "dW",
  "kyc-pending": "du",
  "shareholder-info": "IS",
  "name": "Ic",
  "iban": "Iq",
  "tooltip": "IZ",
  "shareholder-capital": "IR",
  "shareholder-capital-amount": "IQ",
  "deposit-capital": "Ie",
  "valid": "IB",
  "pending": "IE",
  "promised-capital-valid": "Id",
  "promised-capital": "II"
};
