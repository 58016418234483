export default {
  "row": "nA",
  "active": "nY",
  "cell": "nh",
  "empty": "nf",
  "supplier": "nK",
  "supplier-content": "nG",
  "declined": "nr",
  "total-repayment": "nb body-1",
  "amount": "ny",
  "supplier-avatar": "nU mr-16",
  "financing-status": "nj body-2",
  "installments": "nW body-2",
  "overflow-hidden": "nu",
  "ellipsis": "iS",
  "completed-on": "ic",
  "financed-amount": "iq body-2"
};
