/* import __COLOCATED_TEMPLATE__ from './quick-actions.hbs'; */
import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Badge } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import Tether from 'tether';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface ClientsTableItemQuickActionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ClientsTableItemQuickActionsComponent extends Component<ClientsTableItemQuickActionsSignature> {
  badge = Badge;

  BADGE_TYPE = BADGE_TYPE;

  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare upsellManager: Services['upsellManager'];

  get isSoloBasicPlan() {
    // eslint-disable-next-line no-restricted-syntax
    return this.subscriptionManager.currentPricePlan?.groupCode === 'solo_basic';
  }

  get isReceiavableInvoicesTrialAvailable() {
    return (
      this.upsellManager.hasAvailableFreeTrial('receivable_invoices_reminders') &&
      this.subscriptionManager.features.bankAccount
    );
  }

  @action
  // @ts-expect-error
  toggleMenu(showMenu) {
    if (showMenu) {
      this.segment.track('client-list_quick-action-icon_clicked');
      scheduleOnce('afterRender', this, this.positionTether);
    }

    // @ts-expect-error
    this.args.onToggleMenu?.(showMenu);
  }

  positionTether() {
    if (typeof Tether !== 'undefined' && typeof Tether.position === 'function') {
      Tether.position();
    }
  }

  @action
  onSetupReminders() {
    this.segment.track('client-list_quick-action_clicked', {
      quick_action: 'set_up_reminder',
      cta_type: 'create',
    });
    // @ts-expect-error
    this.args.onSetReminders();
  }

  @action
  onEditReminders() {
    this.segment.track('client-list_quick-action_clicked', { quick_action: 'edit_reminder' });
    // @ts-expect-error
    this.args.onSetReminders();
  }

  upsellRemindersTask = dropTask(async () => {
    await this.subscriptionManager.upgradeWithRecommendation('receivable_invoices_reminders');
  });

  @action
  onUpsellReminders() {
    this.segment.track('client-list_quick-action_clicked', {
      quick_action: 'set_up_reminder',
      cta_type: 'upsell',
    });

    this.upsellRemindersTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Clients::Table::Item::QuickActions': typeof ClientsTableItemQuickActionsComponent;
  }
}
