/* import __COLOCATED_TEMPLATE__ from './select-field.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SelectFieldSignature {
  Element: null;
  Args: {
    isDisabled?: boolean;
  };
  Blocks: {
    default: [];
  };
}

const SelectField = templateOnlyComponent<SelectFieldSignature>();

export default SelectField;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::FormFields::SelectField': typeof SelectField;
  }
}
