/* import __COLOCATED_TEMPLATE__ from './compare.hbs'; */
import Component from '@glimmer/component';

import { Badges } from '@repo/cards-kit/components';

import { CARD_FEES, CARD_LEVELS, CARD_WITHDRAW_FEE } from 'qonto/constants/cards';
import { Recycled } from 'qonto/react/cards/components/badges/recycled';

interface CardComparisonModalCompareSignature {
  // The arguments accepted by the component
  Args: {
    isOneCardIncluded?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardComparisonModalCompare extends Component<CardComparisonModalCompareSignature> {
  Metal = Badges.Metal;
  Recycled = Recycled;

  CARD_LEVELS = CARD_LEVELS;
  CARD_FEES = CARD_FEES;
  CARD_WITHDRAW_FEE = CARD_WITHDRAW_FEE;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Modals::Comparison::Compare': typeof CardComparisonModalCompare;
  }
}
