export default {
  "row": "Hf body-1",
  "active": "HK",
  "cell": "HG body-2",
  "empty": "Hr",
  "no-padding": "Hb",
  "cell-content": "Hy",
  "item-disabled": "HU",
  "item-amount": "Hj body-1",
  "centered-cell": "HW",
  "debitor": "Hu",
  "item-counterparty-icon": "OS mr-16",
  "avatar": "Oc",
  "item-counterparty-name": "Oq",
  "item-counterparty-reference": "OZ",
  "item-counterparty-name-status": "OR",
  "item-counterparty-name-text": "OQ",
  "amount-on-hold": "Oe",
  "icon-on-hold": "OB"
};
