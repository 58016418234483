export default {
  "header-cell": "qO",
  "empty": "qm",
  "header-type": "qp",
  "header-rules": "qx",
  "header-date": "qw",
  "header-action": "qN",
  "header-content": "qv caption-bold",
  "active": "qo",
  "row-sidebar": "qn",
  "hidden": "qi",
  "animated": "qA",
  "fadein-item": "qY",
  "animated-cell": "qh"
};
