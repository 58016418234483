export default {
  "row": "vz",
  "row-active": "va",
  "cell": "vH body-2",
  "terminated": "vO",
  "amount": "vm body-1",
  "item-name-cell": "vp",
  "status-icon": "vx",
  "item-name-container": "vw",
  "item-info": "vN",
  "name": "vv body-1",
  "status-displayed": "vo caption"
};
