export default {
  "header-cell": "ah",
  "empty": "af",
  "right": "aK",
  "debitor": "aG",
  "frequency": "ar",
  "date": "ab",
  "status": "ay",
  "amount": "aU",
  "header-content": "aj caption-bold",
  "active": "aW",
  "row--sidebar-open": "au"
};
