export default {
  "row": "HI body-1",
  "active": "Ht",
  "cell": "HM body-2",
  "empty": "HP",
  "no-padding": "Hl",
  "cell-content": "HX",
  "debitor": "HC",
  "avatar": "Hk",
  "debitor-details": "HJ",
  "debitor-name": "Hs",
  "reference": "HF",
  "amount": "Hg",
  "row--sidebar-open": "HD",
  "status": "HV"
};
