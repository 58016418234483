export default {
  "row": "HN",
  "icon": "Hv",
  "cell": "Ho",
  "empty": "Hn",
  "no-padding": "Hi",
  "cell-content": "HA",
  "right": "HY",
  "cube": "Hh"
};
