export default {
  "tile-layout": "eQ",
  "focus": "ee",
  "no-shadow": "eB",
  "tile-layout--start-verification": "eE",
  "header": "ed",
  "headings": "eI",
  "management": "et",
  "logo": "eM",
  "title": "eP title-4",
  "name": "el title-4",
  "loading": "eX",
  "menu-item": "eC body-2",
  "close-account": "ek",
  "content": "eJ"
};
