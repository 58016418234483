export default {
  "container": "JV",
  "content": "JL",
  "subtitle": "JT",
  "switch": "Jz",
  "card-details": "Ja",
  "asset-container": "JH",
  "asset-container-padded": "JO",
  "asset": "Jm",
  "right-container": "Jp",
  "card-brand-title": "Jx",
  "card-brand-badge": "Jw",
  "free-subtitle": "JN",
  "benefit-container": "Jv",
  "checkmark": "Jo",
  "container-metal": "Jn"
};
