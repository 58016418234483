export default {
  "row": "qf body-1",
  "active": "qK",
  "cell": "qG",
  "empty": "qr",
  "ellipsis": "qb",
  "text-secondary": "qy",
  "row-sidebar": "qU",
  "cell-conditions": "qj",
  "animated": "qW",
  "fadein-item": "qu",
  "animated-cell": "ZS",
  "pr-24": "Zc",
  "rule-with-error": "Zq",
  "icon": "ZZ"
};
