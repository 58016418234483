export default {
  "filter": "oS",
  "expression": "oc",
  "conditional-wrapper": "oq mr-8",
  "dropdown-property-select": "oZ",
  "is-advanced-filters-mode": "oR",
  "dropdown-operator-select": "oQ",
  "conditional-text": "oe",
  "property-select": "oB",
  "operator-select": "oE",
  "error-message": "od",
  "values-field": "oI",
  "input-placeholder": "ot",
  "filter-actions": "oM",
  "label-color": "oP",
  "button-container": "ol"
};
