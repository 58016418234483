export default {
  "header-cell": "Xy",
  "header-content": "XU caption-bold",
  "active": "Xj",
  "hidden": "XW",
  "empty": "Xu",
  "align-right": "CS",
  "no-padding": "Cc",
  "request-type": "Cq",
  "amount": "CZ",
  "approval": "CR"
};
