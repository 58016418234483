import { template as template_0927d7ed93f644c89cd794bb2d731598 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import FilterSelect from '@qonto/ui-kit/components/filter-select';
import { t } from 'ember-intl';
import { INVOICE_STATUSES, ISSUE_DATE_FILTER_OPTIONS } from 'qonto/constants/supplier-invoice';
import styles from './filters.strict-module.css';
export default class InvoiceAttachmentsSuggestedFilters extends Component {
    @service
    intl;
    get issueDateOptions() {
        return [
            {
                code: ISSUE_DATE_FILTER_OPTIONS.THIS_MONTH,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.this-month')
            },
            {
                code: ISSUE_DATE_FILTER_OPTIONS.PAST_MONTH,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.last-month')
            }
        ];
    }
    get statusOptions() {
        return [
            {
                code: INVOICE_STATUSES.toReview,
                value: this.intl.t('supplier-invoices.filters.status.value.imported')
            },
            {
                code: INVOICE_STATUSES.toApprove,
                value: this.intl.t('supplier-invoices.filters.status.value.to-approve')
            },
            {
                code: INVOICE_STATUSES.toPay,
                value: this.intl.t('supplier-invoices.filters.status.value.to-pay')
            },
            {
                code: INVOICE_STATUSES.scheduled,
                value: this.intl.t('supplier-invoices.filters.status.value.scheduled')
            },
            {
                code: INVOICE_STATUSES.paid,
                value: this.intl.t('supplier-invoices.filters.status.value.paid')
            }
        ];
    }
    get selectedIssueDate() {
        return this.issueDateOptions.find(({ code })=>code === this.args.issueDate);
    }
    get selectedStatus() {
        return this.statusOptions.find(({ code })=>code === this.args.invoiceStatus);
    }
    @action
    selectIssueDateOption(selected) {
        this.args.updateIssueDate(selected?.code ?? null);
    }
    @action
    selectStatusOption(selected) {
        this.args.updateStatus(selected?.code ?? null);
    }
    static{
        template_0927d7ed93f644c89cd794bb2d731598(`
    <div class={{styles.filters-wrapper}}>
      <FilterSelect
        @placeholder={{t 'supplier-invoices.filters.issue-date'}}
        @value={{this.selectedIssueDate}}
        @options={{this.issueDateOptions}}
        @update={{this.selectIssueDateOption}}
        data-test-invoice-attachments-suggested-issue-date-filter
        as |option|
      >
        {{option.value}}
      </FilterSelect>
      <FilterSelect
        @placeholder={{t 'supplier-invoices.table.header.status'}}
        @value={{this.selectedStatus}}
        @options={{this.statusOptions}}
        @update={{this.selectStatusOption}}
        data-test-invoice-attachments-suggested-status-filter
        as |option|
      >
        {{option.value}}
      </FilterSelect>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
