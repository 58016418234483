/* import __COLOCATED_TEMPLATE__ from './attachment-viewer.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { task } from 'ember-concurrency';
import window from 'ember-window-mock';

import { isAttachmentDeletable } from 'qonto/utils/attachment';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const DEFAULT_MAX_SIZE = 15 * 1e6;
const DEFAULT_EXTENSIONS = 'pdf,jpg,jpeg,png,gif';
const DEFAULT_FILES_LIMIT = 5;

interface AttachmentViewerSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
    isError?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AttachmentViewer extends Component<AttachmentViewerSignature> {
  @service declare abilities: Services['abilities'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare store: Services['store'];
  @service declare intl: Services['intl'];

  @tracked _selectedAttachmentIndex = 0;
  @tracked defaultMaxSize = DEFAULT_MAX_SIZE;
  @tracked defaultExtensions = DEFAULT_EXTENSIONS;
  @tracked defaultFilesLimit = DEFAULT_FILES_LIMIT;
  @tracked showDropzone = false;

  @tracked _attachments = [];

  constructor(owner: unknown, args: AttachmentViewerSignature['Args']) {
    super(owner, args);
    this.setAttachment();
  }

  async setAttachment() {
    // @ts-expect-error
    let { transfer, transaction } = this.args;

    if (transfer) {
      this._attachments = await transfer.attachments;
    } else if (transaction) {
      this._attachments = await transaction.attachments;
    }
  }

  get attachments() {
    // @ts-expect-error
    return this.args.attachments || this._attachments;
  }

  get isDebit() {
    // @ts-expect-error
    return this.args.transaction.side === 'debit';
  }

  get isInternalTransfer() {
    // @ts-expect-error

    let transaction = this.args.transaction;

    if (!transaction.get('isTransfer')) {
      return false;
    }

    let transfer = transaction.get('subject');
    if (transfer) {
      return Boolean(transfer.get('creditBankAccountId'));
    }

    return false;
  }

  get canViewQuickActions() {
    // @ts-expect-error
    let { isBiller, isPagoPaPayment, isNrcPayment, isTapToPay } = this.args.transaction || {};
    return (
      this.hasImproveXSectionFeature &&
      !isBiller &&
      !isPagoPaPayment &&
      !isNrcPayment &&
      !this.isInternalTransfer &&
      !isTapToPay &&
      !this.hasUndeletableAttachments
    );
  }

  get model() {
    // @ts-expect-error
    return this.args.transaction || this.args.transfer;
  }

  get title() {
    // @ts-expect-error
    return this.args.title || this.selectedAttachmentName;
  }

  get hideSidebar() {
    // @ts-expect-error
    return this.args.transfer && !this.displayProbativeFileStatus;
  }

  get deletable() {
    // @ts-expect-error
    let { deletable, transaction } = this.args;
    if (!this.hasAttachments || !transaction) return false;

    let { isFee, isPagoPaPayment, hasUndeletableAttachment } = transaction || {};

    let isSelectedAttachmentDeletable = isAttachmentDeletable(this.selectedAttachment);

    return (
      deletable ??
      ![isFee, isPagoPaPayment, hasUndeletableAttachment, !isSelectedAttachmentDeletable].some(
        Boolean
      )
    );
  }

  get hasUndeletableAttachments() {
    // @ts-expect-error
    return this.args.transaction.hasUndeletableAttachment;
  }

  get canHaveAttachments() {
    // @ts-expect-error
    let { attachmentRequired, attachmentLost } = this.args.transaction || {};

    return attachmentRequired && !attachmentLost;
  }

  get shouldDisplayAttachmentPreview() {
    if (
      // @ts-expect-error
      this.args.transaction &&
      // @ts-expect-error
      this.args.transaction.isBilling &&
      // @ts-expect-error
      this.args.transaction.attachmentsFiles.length === 0
    ) {
      return false;
    }

    // @ts-expect-error
    return this.canHaveAttachments || this.args.transfer;
  }

  get shouldDisplayAttachmentDetails() {
    // @ts-expect-error
    return this.hasAttachments && this.args.transaction;
  }

  get selectedAttachmentIndex() {
    // @ts-expect-error
    let selectedFile = this.args.selectedFile || this.attachments[0]?.file;

    // @ts-expect-error
    let index = this.attachments.findIndex(({ id }) => selectedFile?.fileUrl?.includes(id));

    return index >= 0 ? this._selectedAttachmentIndex + index : 0;
  }

  get selectedAttachment() {
    return this.attachments[this.selectedAttachmentIndex] || {};
  }

  get openInInvoicesEnabled() {
    return this.isInvoice && this.canViewQuickActions;
  }

  get saveInInvoicesEnabled() {
    return !this.isInvoice && this.canViewQuickActions;
  }

  get hasImproveXSectionFeature() {
    return this.abilities.can('viewImproveXSectionFeature attachment');
  }

  get isInvoice() {
    return this.attachments[this.selectedAttachmentIndex]?.documentType === 'invoice';
  }

  get openInInvoicesTooltip() {
    return this.isDebit
      ? this.intl.t('bookkeeping.invoice-preview.actions.open-supplier-invoices.tooltip')
      : this.intl.t('bookkeeping.invoice-preview.actions.open-client-invoices.tooltip');
  }

  get saveInInvoicesTooltip() {
    return this.isDebit
      ? this.intl.t('bookkeeping.invoice-preview.actions.save-supplier-invoices.tooltip')
      : this.intl.t('bookkeeping.invoice-preview.actions.save-client-invoices.tooltip');
  }

  get isFirstAttachmentSelected() {
    return this.selectedAttachmentIndex === 0;
  }

  get isLastAttachmentSelected() {
    return this.selectedAttachmentIndex === this.attachments.length - 1;
  }

  get hasAttachments() {
    return this.attachments.length > 0;
  }

  get hasMultipleAttachments() {
    return this.attachments.length > 1;
  }

  get showPrevButton() {
    return this.hasMultipleAttachments && !this.isFirstAttachmentSelected;
  }

  get showNextButton() {
    return this.hasMultipleAttachments && !this.isLastAttachmentSelected;
  }

  get displayProbativeFileStatus() {
    return ['available', 'corrupted'].includes(this.selectedAttachment.probativeAttachment?.status);
  }

  get selectedAttachmentName() {
    let { file, probativeAttachment, isProbated } = this.selectedAttachment;
    return isProbated ? probativeAttachment?.fileName : file?.fileName;
  }

  get selectedAttachmentType() {
    let { file, probativeAttachment, isProbated } = this.selectedAttachment;
    return isProbated ? probativeAttachment?.fileContentType : file?.fileContentType;
  }

  get selectedAttachmentUrl() {
    let { file, probativeAttachment, isProbated } = this.selectedAttachment;
    return isProbated ? probativeAttachment?.fileUrl : file?.fileUrl;
  }

  get selectedAttachmentDownloadUrl() {
    let { downloadUrl, probativeAttachment, isProbated } = this.selectedAttachment;
    return isProbated ? probativeAttachment.downloadUrl : downloadUrl;
  }

  removeAttachmentTask = task(async (model, file, isUnmatch = false) => {
    // @ts-expect-error
    await this.args.remove(model, file, isUnmatch);

    this._selectedAttachmentIndex = 0;
  });

  @action
  handleRemove() {
    let trackerEvent;
    if (this.openInInvoicesEnabled) {
      trackerEvent = 'transaction-details_unmatch_clicked';
      let isUnmatch = true;
      this.removeAttachmentTask
        .perform(this.model, this.selectedAttachment.file, isUnmatch)
        .catch(ignoreCancelation);
    } else {
      trackerEvent = 'transaction-details_delete_clicked';

      this.modals.open('attachments/confirm-delete-modal', {
        file: this.selectedAttachment.file,
        model: this.model,
        confirmTask: this.removeAttachmentTask,
      });
    }
    this.segment.track(trackerEvent);
  }

  @action
  saveInInvoices() {
    // @ts-expect-error
    this.args.saveInInvoices(this.selectedAttachment.file, this.isDebit, true);
  }

  @action
  handleDownload() {
    window.open(this.selectedAttachmentDownloadUrl);
  }

  @action
  handleNext() {
    if (this.isLastAttachmentSelected) return;
    this._selectedAttachmentIndex++;
  }

  @action
  handlePrev() {
    if (this.isFirstAttachmentSelected) return;
    this._selectedAttachmentIndex--;
  }

  @action
  // @ts-expect-error
  toggleShowDropzone(showDropzone) {
    this.showDropzone = showDropzone;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::AttachmentViewer': typeof AttachmentViewer;
  }
}
