export default {
  "attachment-viewer": "ZU",
  "sidebar": "Zj",
  "attachment-viewer-details": "ZW",
  "attachment-viewer-filename": "Zu body-2",
  "attachment-viewer-actions": "RS",
  "dropzone": "Rc",
  "dropzone-visible": "Rq",
  "attachment-main": "RZ"
};
