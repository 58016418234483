import { template as template_e318d027b9d94c2d9ce1315fa29b57e9 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import FormField from '@qonto/ui-kit/components/form-fields/form-field';
import PowerSelectCustomsTrigger from '@qonto/ui-kit/components/power-select-customs/trigger';
import PowerSelect from 'ember-power-select/components/power-select';
import styles from './type-selector.strict-module.css';
export default class ApprovalWorkflowCondition extends Component {
    @service
    intl;
    static{
        template_e318d027b9d94c2d9ce1315fa29b57e9(`
    <FormField ...attributes @message={{this.errorMessage}} @messageLevel='error'>
      <:field as |field|>
        <PowerSelect
          class={{styles.select}}
          @ariaDescribedBy={{field.messageId}}
          @ariaLabelledBy={{field.labelId}}
          @disabled={{@disabled}}
          @triggerComponent={{component PowerSelectCustomsTrigger selected=@selected.value}}
          @selected={{@selected}}
          @dropdownClass='power-select-custom fit-content'
          @options={{@options}}
          @onChange={{@onChange}}
          @triggerClass='power-select-customs-trigger block {{if
            this.errorMessage
            "error has-danger"
          }}'
          @placeholder='Select'
          data-test-approval-workflow-form-condition-type-selector
          as |option|
        >
          <span class='body-1' data-test-select-operation={{option.key}}>
            {{option.value}}
          </span>
        </PowerSelect>
      </:field>
    </FormField>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
