export default {
  "budget-creation-form": "ES",
  "period-label": "Ec",
  "periods": "Eq",
  "yearly": "EZ",
  "yearly-toggle": "ER",
  "toggle-tooltip": "EQ",
  "tooltip": "Ee",
  "year": "EB",
  "total-budget": "EE",
  "total-budget-value": "Ed",
  "divider": "EI"
};
