export default {
  "container": "Ix",
  "title": "Iw",
  "subtitle": "IN",
  "form": "Iv",
  "uploader": "Io",
  "submit-button": "In",
  "error-message": "Ii",
  "inputs": "IA"
};
