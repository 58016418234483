/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { ApprovalWorkflowTablePlaceholder } from 'qonto/react/components/approval-workflow/table-placeholder';

interface ApprovalWorkflowsTableSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
    isSidepanelOpen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ApprovalWorkflowsTable extends Component<ApprovalWorkflowsTableSignature> {
  approvalWorkflowTablePlaceholder = ApprovalWorkflowTablePlaceholder;

  get placeholderRowsAmount() {
    return variation('feature--boolean-approval-workflow-for-supplier-invoices') ? 3 : 2;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApprovalWorkflow::Table': typeof ApprovalWorkflowsTable;
  }
}
