export default {
  "sidebar-header": "aD",
  "sidebar-header__top": "aV",
  "sidebar-header__close-button": "aL",
  "close-button": "aT",
  "sidebar-header__body": "az",
  "sidebar-header__content": "aa",
  "sidebar-header__date": "aH",
  "infobox": "aO",
  "infobox--link": "am",
  "ghost": "ap",
  "fail": "ax",
  "success": "aw"
};
