export default {
  "row": "Du body-1",
  "active": "VS",
  "cell": "Vc",
  "empty": "Vq",
  "connection": "VZ",
  "connection-avatar": "VR",
  "connection-name": "VQ",
  "member-name": "Ve body-2",
  "member-team": "VB caption",
  "status": "VE",
  "failure": "Vd",
  "error": "VI",
  "hidden": "Vt"
};
