export default {
  "wrapper": "Ng",
  "legend": "ND",
  "operation-code": "NV",
  "table": "NL",
  "footer": "NT",
  "footer-detraction": "Nz",
  "footer-total-g": "Na",
  "footer-total-h": "NH",
  "footer-net-g-h": "NO"
};
