import { template as template_aad215955dea4858a8ae917afe94302c } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency';
import AttachmentsSuggestedModal from 'qonto/components/attachments/attachments-suggested/modal';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
export default class AttachmentsSuggestedModalWrapper extends Component {
    @service
    store;
    @service
    toastFlashMessages;
    @tracked
    transaction;
    constructor(){
        super(...arguments);
        let { transactionId, onClose } = this.args.data;
        this.onClose = onClose;
        this.fetchTransactionModelAndRelsTask.perform(transactionId).catch(ignoreCancelation);
    }
    fetchTransactionModelAndRelsTask = restartableTask(async (transactionId)=>{
        try {
            this.transaction = await this.store.findRecord('transaction', transactionId);
            await this.transaction.belongsTo('initiator').load();
        } catch  {
            // Close modal
            this.args.close();
        }
    });
    get modalData() {
        return {
            transaction: this.transaction,
            onClose: this.onClose
        };
    }
    static{
        template_aad215955dea4858a8ae917afe94302c(`
    {{#unless this.fetchTransactionModelAndRelsTask.isRunning}}
      <AttachmentsSuggestedModal @data={{this.modalData}} @close={{@close}} />
    {{/unless}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
