/* import __COLOCATED_TEMPLATE__ from './hero.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Badges } from '@repo/cards-kit/components';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { Recycled } from 'qonto/react/cards/components/badges/recycled';

interface CardComparisonModalHeroSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class CardComparisonModalHero extends Component<CardComparisonModalHeroSignature> {
  Metal = Badges.Metal;
  Recycled = Recycled;

  @service declare intl: Services['intl'];
  @service declare abilities: Services['abilities'];

  get isMetalCard() {
    // @ts-expect-error
    return this.args.cardLevel === CARD_LEVELS.METAL;
  }

  get subtitle() {
    // @ts-expect-error
    switch (this.args.cardLevel) {
      case CARD_LEVELS.STANDARD:
        return this.intl.t('cards.details.subtitle.standard');
      case CARD_LEVELS.PLUS:
        return this.intl.t('cards.details.subtitle.plus');
      case CARD_LEVELS.METAL:
        return this.intl.t('cards.details.subtitle.metal');
      default:
        return null;
    }
  }

  get title() {
    // @ts-expect-error
    switch (this.args.cardLevel) {
      case CARD_LEVELS.STANDARD:
        return this.intl.t('cards.details.title.standard');
      case CARD_LEVELS.PLUS:
        return this.intl.t('cards.details.title.plus');
      case CARD_LEVELS.METAL:
        return this.intl.t('cards.details.title.metal');
      default:
        return null;
    }
  }

  get metalAssets() {
    return {
      hero: '/illustrations/cards/metal/card-inclined.webp',
      design: '/illustrations/cards/metal/metal-card-design.webp',
    };
  }

  @action
  // @ts-expect-error
  handleIntersect(entries) {
    for (let entry of entries) {
      // @ts-expect-error
      this.footerVisible = !entry.isIntersecting;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Modals::Comparison::Hero': typeof CardComparisonModalHero;
  }
}
