export default {
  "edit": "My",
  "upsell": "MU",
  "options": "Mj",
  "option": "MW body-2 mb-16",
  "option-line": "Mu",
  "category-tags": "PS",
  "option-icons": "Pc",
  "option-icon": "Pq",
  "valid": "PZ"
};
