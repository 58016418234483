export default {
  "container": "la",
  "content": "lH",
  "table": "lO",
  "table-body": "lm",
  "title": "lp",
  "title-with-badge": "lx",
  "metal-card-title": "lw",
  "subtitle": "lN",
  "card-image": "lv",
  "button-container": "lo"
};
