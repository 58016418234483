export default {
  "wrapper": "Nr",
  "legend": "Nb",
  "input": "Ny",
  "table": "NU",
  "footer": "Nj",
  "footer-total-m": "NW",
  "footer-total-n": "Nu",
  "footer-balance": "vS"
};
