/* import __COLOCATED_TEMPLATE__ from './upsell.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { EmptyStatesDiscover } from '@repo/domain-kit/pricing';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface ApprovalWorkflowsUpsellSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ApprovalWorkflowsUpsell extends Component<ApprovalWorkflowsUpsellSignature> {
  emptyStatesDiscover = EmptyStatesDiscover;
  BADGE_TYPE = BADGE_TYPE;

  @service declare intl: Services['intl'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  get hasAvailableFreeTrial() {
    return this.upsellManager.hasAvailableFreeTrial('approval_workflows');
  }

  constructor(owner: unknown, args: ApprovalWorkflowsUpsellSignature['Args']) {
    super(owner, args);
    this.segment.track('empty_state_shown', {
      'empty-state_type': 'upsell',
      'empty-state_feature': 'approval-workflows',
    });
  }

  onCtaClickTask = dropTask(async () => {
    await this.subscriptionManager.upgradeWithRecommendation('approval_workflows');
  });

  @action
  onCtaClick() {
    this.segment.track('approval-workflow_CTA_clicked', {
      cta_type: 'upsell',
    });
    this.onCtaClickTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApprovalWorkflow::Upsell': typeof ApprovalWorkflowsUpsell;
  }
}
