/* import __COLOCATED_TEMPLATE__ from './image.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeHighlight } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { CARD_DESIGNS, CARD_LEVELS } from 'qonto/constants/cards';
import { CopyToClipboard } from 'qonto/react/shared/components/copy-to-clipboard';

const MASK_CHAR = '•';
const THREE_DOTS = MASK_CHAR.repeat(3);
const FULL_MASK_PAN = MASK_CHAR.repeat(16);

const CARD_IMAGES = {
  metal: '/illustrations/cards/card-x-chipless.svg',
  plus: '/illustrations/cards/card-plus-chipless.svg',
  standard: '/illustrations/cards/card-one-chipless.svg',
  'standard.recycled.plastic.2023': '/illustrations/cards/card-one-chipless.svg',
  virtual: '/illustrations/cards/card-virtual-chipless.svg',
  'virtual.default.2017': '/illustrations/cards/card-virtual-chipless.svg',
  flash: '/illustrations/cards/card-flash-chipless.svg',
  'flash.default.2021': '/illustrations/cards/card-flash-chipless.svg',
  advertising: '/illustrations/cards/card-advertising-chipless.svg',
  'advertising.default.2023': '/illustrations/cards/card-advertising-chipless.svg',
  'plus.black.2023': '/illustrations/cards/card-plus.black.2023-chipless.svg',
  'plus.recycled.plastic.silver.2023':
    '/illustrations/cards/card-plus.recycled.plastic.silver.2023-chipless.svg',
  'plus.recycled.plastic.black.2023':
    '/illustrations/cards/card-plus.recycled.plastic.black.2023-chipless.svg',
  'plus.recycled.plastic.lilac.2023':
    '/illustrations/cards/card-plus.recycled.plastic.lilac.2023-chipless.svg',
  'metal.graphite.black.2019': '/illustrations/cards/card-metal.graphite.black.2019-chipless.svg',
  'metal.mineral.gray.2024': '/illustrations/cards/card-metal.mineral.gray.2024-chipless.svg',
  'metal.sand.gold.2024': '/illustrations/cards/card-metal.sand.gold.2024-chipless.svg',
};

interface CardImageSignature {
  // The arguments accepted by the component
  Args: {
    isDisabled?: boolean;
    isRevealed?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardImageComponent extends Component<CardImageSignature> {
  badgeHighlight = BadgeHighlight;
  CopyToClipboard = CopyToClipboard;

  @service declare intl: Services['intl'];

  isLoaded = false;

  get fullyMasked() {
    // @ts-expect-error
    return this.args.pan === FULL_MASK_PAN;
  }

  get expiration() {
    // @ts-expect-error
    if (!this.args.expiration) return THREE_DOTS;
    // @ts-expect-error
    return dayjs(this.args.expiration).format('MM/YY');
  }

  get cvv() {
    // @ts-expect-error
    return this.args.cvv || THREE_DOTS;
  }

  get maskPanArray() {
    // @ts-expect-error
    return this.args.pan.match(/.{1,4}/g);
  }

  get imageURI() {
    // @ts-expect-error
    let design = this.args.design || CARD_LEVELS.STANDARD;
    // @ts-expect-error
    return CARD_IMAGES[design];
  }

  get caption() {
    // @ts-expect-error
    let { cardLevel } = this.args;
    switch (cardLevel) {
      case CARD_LEVELS.FLASH:
        return this.intl.t('cards.image.badge.flash');
      case CARD_LEVELS.VIRTUAL:
        return this.intl.t('cards.image.badge.virtual');
      default:
        return null;
    }
  }

  get isBlack() {
    // @ts-expect-error
    let { design } = this.args;
    return (
      design === CARD_LEVELS.METAL ||
      design === CARD_DESIGNS.PLUS_BLACK_2023 ||
      design === CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_BLACK_2023 ||
      design === CARD_DESIGNS.METAL_GRAPHITE_BLACK_2019
    );
  }

  get copyButtonLocalClass() {
    return `copy-btn ${this.isBlack ? 'black' : ''}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Image': typeof CardImageComponent;
  }
}
