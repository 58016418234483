export default {
  "sidebar": "Vb",
  "scroll": "Vy",
  "header": "VU",
  "close": "Vj",
  "vat": "VW",
  "labels": "Vu",
  "actions": "LS",
  "buttons-container": "Lc"
};
