export default {
  "header-cell": "vJ caption-bold",
  "sortable": "vs",
  "animated": "vF",
  "fadein-item": "vg",
  "animated-cell": "vD",
  "col1": "vV",
  "header-content": "vL caption-bold",
  "active": "vT"
};
