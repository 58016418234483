export default {
  "chip": "Ff",
  "chip--hidden": "FK",
  "chip--collapsed": "FG",
  "chip--selected": "Fr",
  "chip--disabled": "Fb",
  "wrapper": "Fy",
  "content": "FU body-1",
  "clear": "Fj",
  "clear-icon": "FW"
};
