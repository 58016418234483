export default {
  "header-cell": "sX",
  "empty": "sC",
  "emitter": "sk",
  "date": "sJ",
  "amount": "ss",
  "status": "sF",
  "header-content": "sg caption-bold",
  "active": "sD"
};
