export default {
  "row": "CQ",
  "active": "Ce",
  "status": "CB",
  "align-right": "CE",
  "quick-actions": "Cd",
  "cell-approve": "CI",
  "account-select": "Ct",
  "hidden": "CM",
  "no-padding": "CP",
  "animated": "Cl",
  "fadein-item": "CX",
  "cell": "CC body-2",
  "cell-content": "Ck",
  "amount": "CJ body-1",
  "subtitle": "Cs caption"
};
