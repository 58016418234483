export default {
  "row": "zK",
  "cell": "zG body-2",
  "empty": "zr",
  "cell-content": "zb",
  "cell-content--amount": "zy body-1",
  "icon": "zU",
  "description": "zj",
  "tertiary": "zW",
  "details": "zu"
};
