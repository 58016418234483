export default {
  "content": "es",
  "balance": "eF",
  "balance-failed": "eg",
  "bank-infos": "eD",
  "bank-name": "eV",
  "bank-identifier": "eL",
  "bank-iban": "eT",
  "footer-container": "ez",
  "links": "ea",
  "links--start-verification": "eH",
  "link": "eO",
  "start-verification-link": "em",
  "last-sync": "ep"
};
