export default {
  "attachments-suggested-sidebar": "RT",
  "aside-section": "Rz",
  "search-section": "Ra",
  "scrolled-top": "RH",
  "aside-title": "RO caption-bold",
  "search-input": "Rm",
  "toolbar-row": "Rp",
  "invoices-list-section": "Rx",
  "attachments-suggested-sidebar-footer": "Rw",
  "pagination-wrapper": "RN",
  "pagination-loading": "Rv",
  "cta-wrapper": "Ro",
  "cancel-button": "Rn",
  "content": "Ri",
  "illustration": "RA mb-32",
  "title": "RY mb-8 title-4",
  "subtitle": "Rh body-2"
};
