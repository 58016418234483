export default {
  "aside": "Zx",
  "aside-section": "Zw",
  "aside-content": "ZN",
  "aside-footer": "Zv",
  "aside-title": "Zo caption-bold",
  "accountant-upsell": "Zn",
  "file-status": "Zi",
  "file-status-icon": "ZA",
  "file-status-text": "ZY",
  "file-status-label": "Zh body-1",
  "file-status-description": "Zf body-2"
};
