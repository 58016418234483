export default {
  "card": "EM",
  "header": "EP",
  "budget-caption": "El caption",
  "amount-spent-section": "EX",
  "amount-spent-icon-container": "EC",
  "amount-spent-title": "Ek title-3",
  "amount-budgeted-section": "EJ",
  "amount-budgeted-title": "Es title-3"
};
