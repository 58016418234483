/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ORGA_STATUS } from 'qonto/constants/organization';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface DiscoverTrialConfirmSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DiscoverTrialConfirmComponent extends Component<DiscoverTrialConfirmSignature> {
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];
  @service declare modals: Services['modals'];
  @service declare store: Services['store'];
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @tracked trialPricePlan;
  // @ts-expect-error
  @tracked subscription;
  @tracked hasInsufficientFunds = false;

  // @ts-expect-error
  trialPricePlanId = this.args.data.trialInfo.productId;
  // @ts-expect-error
  recurrence = this.args.data.trialInfo.recurrence;

  constructor(owner: unknown, args: DiscoverTrialConfirmSignature['Args']) {
    super(owner, args);
    if (!this.isAccountClosed) {
      this.fetchDataTask.perform().catch(ignoreCancelation);
    }
  }

  get context() {
    return {
      subscription: this.subscription,
      recurrence: this.recurrence,
      hasInsufficientFunds: this.hasInsufficientFunds,
    };
  }

  @action
  // @ts-expect-error
  transitionToNext(hasProductChangeNotAvailableError) {
    this.router.refresh('protected');

    if (hasProductChangeNotAvailableError) {
      this.modals.open('discover/trial/success-italy');
    } else {
      this.modals.open('discover/trial/success', {
        recurrence: this.recurrence,
        currentPlan: this.subscriptionManager.currentPricePlan,
        targetPlan: this.trialPricePlan,
      });
    }

    // @ts-expect-error
    this.args.close();
  }

  get isAccountClosed() {
    return this.organizationManager.organization.status === ORGA_STATUS.DEACTIVATED;
  }

  fetchDataTask = dropTask(async () => {
    this.trialPricePlan = await this.store.findRecord(
      'subscription-product',
      this.trialPricePlanId
    );
    this.subscription = this.store.createRecord('organization-subscription', {
      organization: this.organizationManager.organization,
      product: this.trialPricePlan,
      recurrence: this.recurrence,
    });

    try {
      await this.subscription.estimate();
      // @ts-expect-error
    } catch ({ payload, status }) {
      if (status === 422) {
        let errors;
        errors = payload.errors;
        this.hasInsufficientFunds = errors.some(
          // @ts-expect-error
          ({ code }) => code === 'balance_insufficient_funds'
        );
      } else {
        let errorMessage = this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(errorMessage);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::Trial::Confirm': typeof DiscoverTrialConfirmComponent;
  }
}
