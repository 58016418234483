export default {
  "wrapper": "wj",
  "legend": "wW",
  "grid": "wu",
  "subtitle": "NS body-2",
  "fiscal-code": "Nc",
  "personal-data-name": "Nq",
  "personal-data-birth": "NZ",
  "personal-data": "NR",
  "tax-residence": "NQ",
  "payer-agent": "Ne",
  "errors": "NB",
  "business": "NE",
  "address": "Nd"
};
