export default {
  "container": "PV",
  "container-metal": "PL",
  "pickers": "PT",
  "picker-wrapper": "Pz",
  "picker": "Pa",
  "checked": "PH",
  "lilac": "PO",
  "black": "Pm",
  "mineral-gray": "Pp",
  "sand-gold": "Px",
  "graphite-black": "Pw"
};
