export default {
  "wrapper": "Nm",
  "subtitle": "Np body-2",
  "legend": "Nx",
  "table": "Nw",
  "footer": "NN",
  "footer-total-c": "Nv",
  "footer-total-d": "No",
  "footer-balance": "Nn"
};
