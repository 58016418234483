export default {
  "status-avatar": "sV mr-16",
  "row": "sL",
  "active": "sT",
  "animated": "sz",
  "fadein-item": "sa",
  "cell": "sH",
  "cell-content": "sO",
  "disabled": "sm",
  "not-disabled": "sp",
  "cell-amount": "sx",
  "ghost": "sw",
  "validated": "sN",
  "status-pin": "sv",
  "item-emitter-name": "so",
  "item-emitter-name-text": "sn"
};
