export default {
  "row": "Ca",
  "active": "CH",
  "status": "CO",
  "align-right": "Cm",
  "animated": "Cp",
  "fadein-item": "Cx",
  "cell": "Cw body-2",
  "cell-content": "CN",
  "amount": "Cv body-1",
  "subtitle": "Co caption",
  "no-padding": "Cn",
  "note": "Ci"
};
