export default {
  "connection-sidebar-header": "Dp",
  "title": "Dx",
  "close": "Dw",
  "api-client": "DN",
  "api-client-avatar": "Dv",
  "api-client-name": "Do",
  "disclaimer": "Dn",
  "expired": "Di",
  "connect-button": "DA"
};
