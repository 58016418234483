export default {
  "row": "Ev body-1",
  "cell": "Eo",
  "counterparty": "En",
  "counterparty-icon": "Ei",
  "avatar": "EA",
  "icon": "EY",
  "counterparty-content": "Eh",
  "item-counterparty-name": "Ef",
  "counterparty-name": "EK body-1",
  "counterparty-status": "EG",
  "member": "Er",
  "method": "Eb",
  "operation-date": "Ey",
  "amount": "EU",
  "remove-transaction": "Ej",
  "menu-item": "EW",
  "remove": "Eu",
  "tooltip": "dS",
  "empty": "dc"
};
