export default {
  "container": "lc",
  "is-loaded": "lq",
  "disabled": "lZ",
  "image": "lR",
  "infos": "lQ",
  "fully-masked": "le",
  "black": "lB",
  "infos-type--virtual": "lE",
  "infos-holder": "ld",
  "infos-pan": "lI",
  "infos-pan-numbers": "lt",
  "infos-exp-date": "lM",
  "infos-cvv": "lP",
  "infos-caption": "ll",
  "copy-btn": "lX",
  "description-details": "lC"
};
