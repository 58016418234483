export default {
  "wrapper": "mL",
  "header": "mT",
  "no-separator": "mz",
  "tabs": "ma",
  "tab": "mH body-1 overlay",
  "selected": "mO",
  "organization-list": "mm",
  "content": "mp",
  "empty-state": "mx",
  "illustration": "mw",
  "body": "mN body-1",
  "cta": "mv btn btn--tertiary"
};
