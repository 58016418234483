export default {
  "sub-menu": "pr",
  "collapsed": "pb",
  "collapse-left": "py",
  "responsive": "pU",
  "expanded": "pj",
  "no-slide": "pW",
  "visible": "pu",
  "hidden": "xS",
  "group": "xc",
  "promo-box": "xq",
  "title": "xZ",
  "quick-access-padding": "xR",
  "scrolled-top": "xQ",
  "scrollable-content": "xe"
};
