export default {
  "wrapper": "QO",
  "core-information": "Qm",
  "iban": "Qp",
  "bic": "Qx",
  "full-line": "Qw",
  "secondary-information": "QN",
  "title": "Qv",
  "rib": "Qo",
  "rib-copy": "Qn",
  "flex-column": "Qi",
  "label": "QA body-1",
  "value": "QY body-2",
  "right-spaced": "Qh",
  "name": "Qf title-3",
  "disclaimer": "QK"
};
