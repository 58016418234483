export default {
  "header": "Jy",
  "result": "JU",
  "result-value": "Jj",
  "result-value-key": "JW",
  "result-value-icon": "Ju",
  "green": "sS",
  "red": "sc",
  "result-value-description": "sq",
  "result-title": "sZ",
  "chevron": "sR",
  "detail": "sQ",
  "detail-title": "se",
  "detail-value": "sB",
  "tooltip-wrapper": "sE",
  "tooltip": "sd",
  "disclaimer": "sI"
};
