export default {
  "row": "FP",
  "active": "Fl",
  "cell": "FX",
  "name-cell": "FC",
  "email-cell": "Fk",
  "actions-cell": "FJ",
  "name-container": "Fs",
  "quick-actions-wrapper": "FF",
  "empty": "Fg",
  "name-icon-container": "FD",
  "icon": "FV",
  "tooltip-container": "FL",
  "missing-details-container": "FT",
  "missing-details-label": "Fz body-2",
  "warning-icon": "Fa FV"
};
