/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

// @ts-expect-error
import { ensureSafeComponent } from '@embroider/util';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

// @ts-expect-error
import { REQUEST_STATUS_COLORS } from 'qonto/constants/requests';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface CardRequestsTableItemSignature {
  // The arguments accepted by the component
  Args: {
    isHighlighted?: boolean;
    isSidebarOpen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class CardRequestsTableItemComponent extends Component<CardRequestsTableItemSignature> {
  @service declare notifierCounterManager: Services['notifierCounterManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  // @ts-expect-error
  @reads('organizationManager.organization.activeAccounts') activeAccounts;
  constructor(owner: unknown, args: CardRequestsTableItemSignature['Args']) {
    super(owner, args);
    this.setBankAccountTask.perform().catch(ignoreCancelation);
  }

  get requestComponentName() {
    // @ts-expect-error
    let type = dasherize(this.args.request.requestType);
    return ensureSafeComponent(`request/table/cell/${type}`, this);
  }

  get requestAmount() {
    // @ts-expect-error
    let { request } = this.args;
    switch (request.requestType) {
      case 'virtual_card':
        return { value: request.paymentMonthlyLimit, currency: request.currency };
      case 'flash_card':
        return { value: request.paymentLifespanLimit, currency: request.currency };
    }
  }

  setBankAccountTask = dropTask(async () => {
    try {
      let { currentAccount } = this.organizationManager;
      // @ts-expect-error
      let bankAccount = await this.args.request.belongsTo('bankAccount').load();
      if (!bankAccount) {
        // @ts-expect-error
        this.args.request.bankAccount = currentAccount;
      }
      // @ts-expect-error
      return this.args.request.bankAccount;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get canShowAccountSelector() {
    return this.activeAccounts.length > 1;
  }

  get status() {
    // @ts-expect-error
    let { status, displayedStatus } = this.args.request;

    return {
      displayedStatus,
      color: REQUEST_STATUS_COLORS[status],
    };
  }

  @action
  // @ts-expect-error
  onBankAccountSelect(account) {
    // @ts-expect-error
    this.args.onBankAccountSelect(this.args.request, account);
    this.setBankAccountTask.perform().catch(ignoreCancelation);
  }

  @action
  onBankAccountOpen() {
    this.segment.track('bank_account_picker_opened', {
      origin: 'cards',
    });
  }

  @action
  // @ts-expect-error
  onBankAccountInteraction(event) {
    // Prevent the sidebar to open when interacting with the account select trigger
    event.stopPropagation();
  }

  onCardReject = dropTask(async () => {
    // @ts-expect-error
    await this.args.handleQuickDecline.perform(this.args.request);
    await this.notifierCounterManager.updateCounter();
  });

  onCardApprove = dropTask(async () => {
    // @ts-expect-error
    await this.args.handleQuickApprove.perform(this.args.request);
    await this.notifierCounterManager.updateCounter();
  });

  @action
  // @ts-expect-error
  onRowClick(id) {
    if (this.onCardApprove.isRunning || this.onCardReject.isRunning) {
      return;
    }

    // @ts-expect-error
    this.args.highlightItem(id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::RequestsTable::Approver::Item': typeof CardRequestsTableItemComponent;
  }
}
