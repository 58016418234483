export default {
  "dates-filter": "oX",
  "dates-wrapper": "oC",
  "date-interval": "ok",
  "date-field-wrapper": "oJ",
  "dates-conditional": "os",
  "placeholder": "oF",
  "error": "og",
  "error-message": "oD body-2"
};
